import { IIconProps } from "@/components/icons/interface";
const MachinesIcon = ({ width, height, color }: IIconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 7.5V5.7C18 4.5799 18 4.01984 17.782 3.59202C17.5903 3.21569 17.2843 2.90973 16.908 2.71799C16.4802 2.5 15.9201 2.5 14.8 2.5H9.2C8.0799 2.5 7.51984 2.5 7.09202 2.71799C6.71569 2.90973 6.40973 3.21569 6.21799 3.59202C6 4.01984 6 4.5799 6 5.7V7.5M6 18.5C5.07003 18.5 4.60504 18.5 4.22354 18.3978C3.18827 18.1204 2.37962 17.3117 2.10222 16.2765C2 15.895 2 15.43 2 14.5V12.3C2 10.6198 2 9.77976 2.32698 9.13803C2.6146 8.57354 3.07354 8.1146 3.63803 7.82698C4.27976 7.5 5.11984 7.5 6.8 7.5H17.2C18.8802 7.5 19.7202 7.5 20.362 7.82698C20.9265 8.1146 21.3854 8.57354 21.673 9.13803C22 9.77976 22 10.6198 22 12.3V14.5C22 15.43 22 15.895 21.8978 16.2765C21.6204 17.3117 20.8117 18.1204 19.7765 18.3978C19.395 18.5 18.93 18.5 18 18.5M15 11H18M9.2 22.5H14.8C15.9201 22.5 16.4802 22.5 16.908 22.282C17.2843 22.0903 17.5903 21.7843 17.782 21.408C18 20.9802 18 20.4201 18 19.3V17.7C18 16.5799 18 16.0198 17.782 15.592C17.5903 15.2157 17.2843 14.9097 16.908 14.718C16.4802 14.5 15.9201 14.5 14.8 14.5H9.2C8.0799 14.5 7.51984 14.5 7.09202 14.718C6.71569 14.9097 6.40973 15.2157 6.21799 15.592C6 16.0198 6 16.5799 6 17.7V19.3C6 20.4201 6 20.9802 6.21799 21.408C6.40973 21.7843 6.71569 22.0903 7.09202 22.282C7.51984 22.5 8.07989 22.5 9.2 22.5Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const MachinesAdminIcon = ({ width, height, color }: IIconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 7.5V5.7C18 4.5799 18 4.01984 17.782 3.59202C17.5903 3.21569 17.2843 2.90973 16.908 2.71799C16.4802 2.5 15.9201 2.5 14.8 2.5H9.2C8.0799 2.5 7.51984 2.5 7.09202 2.71799C6.71569 2.90973 6.40973 3.21569 6.21799 3.59202C6 4.01984 6 4.5799 6 5.7V7.5M6 18.5C5.07003 18.5 4.60504 18.5 4.22354 18.3978C3.18827 18.1204 2.37962 17.3117 2.10222 16.2765C2 15.895 2 15.43 2 14.5V12.3C2 10.6198 2 9.77976 2.32698 9.13803C2.6146 8.57354 3.07354 8.1146 3.63803 7.82698C4.27976 7.5 5.11984 7.5 6.8 7.5H17.2C18.8802 7.5 19.7202 7.5 20.362 7.82698C20.9265 8.1146 21.3854 8.57354 21.673 9.13803C22 9.77976 22 10.6198 22 12.3V14.5C22 15.43 22 15.895 21.8978 16.2765C21.6204 17.3117 20.8117 18.1204 19.7765 18.3978C19.395 18.5 18.93 18.5 18 18.5M15 11H18M9.2 22.5H14.8C15.9201 22.5 16.4802 22.5 16.908 22.282C17.2843 22.0903 17.5903 21.7843 17.782 21.408C18 20.9802 18 20.4201 18 19.3V17.7C18 16.5799 18 16.0198 17.782 15.592C17.5903 15.2157 17.2843 14.9097 16.908 14.718C16.4802 14.5 15.9201 14.5 14.8 14.5H9.2C8.0799 14.5 7.51984 14.5 7.09202 14.718C6.71569 14.9097 6.40973 15.2157 6.21799 15.592C6 16.0198 6 16.5799 6 17.7V19.3C6 20.4201 6 20.9802 6.21799 21.408C6.40973 21.7843 6.71569 22.0903 7.09202 22.282C7.51984 22.5 8.07989 22.5 9.2 22.5Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { MachinesIcon, MachinesAdminIcon };
