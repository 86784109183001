import { IIconProps } from "@/components/icons/interface";
const GoMakeIcon = ({ width, height, color }: IIconProps) => {
  return (
    <svg
      width="54"
      height="55"
      viewBox="0 0 54 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="54" height="54" rx="16" fill="white" />
      <path
        d="M16.6283 31.7028C16.6283 34.2302 15.0977 35.7608 12.5125 35.7608C9.74074 35.7608 8.04297 33.9473 8.04297 30.9311C8.04297 27.96 9.74074 26.1594 12.4675 26.1594C14.6347 26.1594 16.2682 27.4391 16.5447 29.317H14.5961C14.3132 28.3459 13.5221 27.7671 12.4739 27.7671C10.9433 27.7671 10.0237 28.9311 10.0237 30.9054C10.0237 32.9183 11.0012 34.1466 12.5446 34.1466C13.8115 34.1466 14.6926 33.3685 14.7312 32.2173L14.7376 32.0694H12.7183V30.6546H16.6283V31.7028Z"
        fill="#2E3092"
      />
      <path
        d="M20.9177 35.7479C18.8341 35.7479 17.5029 34.4103 17.5029 32.1466C17.5029 29.915 18.8534 28.5581 20.9177 28.5581C22.9821 28.5581 24.3326 29.9086 24.3326 32.1466C24.3326 34.4167 23.0014 35.7479 20.9177 35.7479ZM20.9177 34.3138C21.8374 34.3138 22.4226 33.5357 22.4226 32.153C22.4226 30.7832 21.8309 29.9922 20.9177 29.9922C20.0045 29.9922 19.4065 30.7832 19.4065 32.153C19.4065 33.5357 19.9917 34.3138 20.9177 34.3138Z"
        fill="#2E3092"
      />
      <path
        d="M8.17159 46.13V39.236H9.98512V40.4836H10.0237C10.3131 39.654 11.0527 39.1074 11.9916 39.1074C12.9884 39.1074 13.6829 39.6219 13.9144 40.5222H13.953C14.2617 39.654 15.0849 39.1074 16.0945 39.1074C17.445 39.1074 18.3518 40.0335 18.3518 41.4225V46.13H16.474V41.892C16.474 41.0946 16.0752 40.6573 15.3485 40.6573C14.6347 40.6573 14.1652 41.1782 14.1652 41.9435V46.13H12.3581V41.8341C12.3581 41.0946 11.9401 40.6573 11.2456 40.6573C10.5317 40.6573 10.0494 41.1975 10.0494 41.9692V46.13H8.17159Z"
        fill="#2E3092"
      />
      <path
        d="M22.2039 44.8824C23.0207 44.8824 23.6959 44.3486 23.6959 43.6026V43.101L22.2425 43.1911C21.5415 43.2425 21.1428 43.5576 21.1428 44.0464C21.1428 44.5609 21.5673 44.8824 22.2039 44.8824ZM21.5866 46.2393C20.2746 46.2393 19.2778 45.3904 19.2778 44.1493C19.2778 42.8952 20.2425 42.1685 21.9596 42.0656L23.6959 41.9627V41.5061C23.6959 40.8631 23.2457 40.4901 22.5383 40.4901C21.8374 40.4901 21.3936 40.8373 21.3036 41.3454H19.5865C19.6573 40.0077 20.7956 39.0881 22.6219 39.0881C24.3905 39.0881 25.5416 40.0013 25.5416 41.3904V46.13H23.7281V45.0753H23.6895C23.3036 45.8085 22.4483 46.2393 21.5866 46.2393Z"
        fill="#2E3092"
      />
      <path
        d="M28.7056 42.0013L31.0465 39.236H33.1751L30.5449 42.1943L33.3038 46.13H31.143L29.1687 43.3261L28.6799 43.8534V46.13H26.8021V36.8501H28.6799V42.0013H28.7056Z"
        fill="#2E3092"
      />
      <path
        d="M36.6157 40.4772C35.7925 40.4772 35.2009 41.101 35.1366 41.9627H38.0627C38.0241 41.0817 37.4517 40.4772 36.6157 40.4772ZM38.0884 44.0207H39.8119C39.6061 45.3712 38.3714 46.2779 36.6672 46.2779C34.5449 46.2779 33.2716 44.921 33.2716 42.7152C33.2716 40.5158 34.5578 39.0881 36.5964 39.0881C38.6029 39.0881 39.8698 40.445 39.8698 42.5415V43.1139H35.1173V43.2296C35.1173 44.2136 35.7411 44.8888 36.6993 44.8888C37.3874 44.8888 37.9148 44.548 38.0884 44.0207Z"
        fill="#2E3092"
      />
      <path
        d="M40.3648 23.99C39.2483 23.99 38.3398 24.8984 38.3398 26.015C38.3398 27.1315 39.2483 28.04 40.3648 28.04C41.4814 28.04 42.3898 27.1315 42.3898 26.015C42.3898 24.8984 41.4814 23.99 40.3648 23.99ZM40.3648 24.3015C41.3128 24.3015 42.0783 25.067 42.0783 26.015C42.0783 26.963 41.3128 27.7285 40.3648 27.7285C39.4168 27.7285 38.6514 26.963 38.6514 26.015C38.6514 25.067 39.4168 24.3015 40.3648 24.3015ZM39.7418 25.0804V26.9496H40.0533V26.3265H40.5839L40.8322 26.9496H41.1437L40.8808 26.2876C41.1242 26.203 41.2995 25.976 41.2995 25.7035C41.2995 25.3591 41.0208 25.0804 40.6764 25.0804H39.7418ZM40.0533 25.3919H40.6764C40.862 25.3919 40.9879 25.5179 40.9879 25.7035C40.9879 25.889 40.862 26.015 40.6764 26.015H40.0533V25.3919Z"
        fill="#2E3092"
      />
    </svg>
  );
};

export { GoMakeIcon };
