const CustomerServiceIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 18.5V12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5V18.5M5.5 21.5C4.11929 21.5 3 20.3807 3 19V17C3 15.6193 4.11929 14.5 5.5 14.5C6.88071 14.5 8 15.6193 8 17V19C8 20.3807 6.88071 21.5 5.5 21.5ZM18.5 21.5C17.1193 21.5 16 20.3807 16 19V17C16 15.6193 17.1193 14.5 18.5 14.5C19.8807 14.5 21 15.6193 21 17V19C21 20.3807 19.8807 21.5 18.5 21.5Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { CustomerServiceIcon };
