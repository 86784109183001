const ActionsIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 4V2.5M5.06066 5.56066L4 4.5M5.06066 13.5L4 14.5607M13 5.56066L14.0607 4.5M3.5 9.5H2M15.8645 16.6896L13.3727 21.317C13.0881 21.8457 12.9457 22.11 12.7745 22.1769C12.6259 22.2349 12.4585 22.2185 12.324 22.1328C12.1689 22.034 12.0806 21.7471 11.9038 21.1733L8.44519 9.94525C8.3008 9.47651 8.2286 9.24213 8.28669 9.08383C8.33729 8.94595 8.44595 8.83729 8.58383 8.7867C8.74213 8.72861 8.9765 8.8008 9.44525 8.94519L20.6732 12.4038C21.247 12.5806 21.5339 12.669 21.6327 12.824C21.7185 12.9586 21.7348 13.1259 21.6768 13.2745C21.61 13.4458 21.3456 13.5881 20.817 13.8728L16.1896 16.3645C16.111 16.4068 16.0717 16.4279 16.0374 16.4551C16.0068 16.4792 15.9792 16.5068 15.9551 16.5374C15.9279 16.5717 15.9068 16.611 15.8645 16.6896Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { ActionsIcon };
