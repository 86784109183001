import { IIconProps } from "@/components/icons/interface";
const ProductionFloorIcon = ({ width, height, color }: IIconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 22.5H15C20 22.5 22 20.5 22 15.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5 19C16.6 19 17.5 18.1 17.5 17V8C17.5 6.9 16.6 6 15.5 6C14.4 6 13.5 6.9 13.5 8V17C13.5 18.1 14.39 19 15.5 19Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 19C9.6 19 10.5 18.1 10.5 17V13.5C10.5 12.4 9.6 11.5 8.5 11.5C7.4 11.5 6.5 12.4 6.5 13.5V17C6.5 18.1 7.39 19 8.5 19Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { ProductionFloorIcon };
