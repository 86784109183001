import { IIconProps } from "@/components/icons/interface";
const BankingIcon = ({ width, height, color }: IIconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 9.49999V17.5M9.5 9.49999V17.5M14.5 9.49999V17.5M19 9.49999V17.5M3 19.1L3 19.9C3 20.46 3 20.7401 3.10899 20.954C3.20487 21.1421 3.35785 21.2951 3.54601 21.391C3.75992 21.5 4.03995 21.5 4.6 21.5H19.4C19.9601 21.5 20.2401 21.5 20.454 21.391C20.6422 21.2951 20.7951 21.1421 20.891 20.954C21 20.7401 21 20.46 21 19.9V19.1C21 18.5399 21 18.2599 20.891 18.046C20.7951 17.8578 20.6422 17.7049 20.454 17.609C20.2401 17.5 19.9601 17.5 19.4 17.5H4.6C4.03995 17.5 3.75992 17.5 3.54601 17.609C3.35785 17.7049 3.20487 17.8578 3.10899 18.046C3 18.2599 3 18.5399 3 19.1ZM11.6529 3.57712L4.25291 5.22156C3.80585 5.32091 3.58232 5.37058 3.41546 5.49079C3.26829 5.59682 3.15273 5.74088 3.08115 5.90756C3 6.09651 3 6.3255 3 6.78346L3 7.89999C3 8.46004 3 8.74007 3.10899 8.95398C3.20487 9.14214 3.35785 9.29512 3.54601 9.391C3.75992 9.49999 4.03995 9.49999 4.6 9.49999H19.4C19.9601 9.49999 20.2401 9.49999 20.454 9.391C20.6422 9.29512 20.7951 9.14214 20.891 8.95398C21 8.74007 21 8.46004 21 7.89999V6.78346C21 6.3255 21 6.09652 20.9188 5.90756C20.8473 5.74089 20.7317 5.59682 20.5845 5.49079C20.4177 5.37059 20.1942 5.32091 19.7471 5.22157L12.3471 3.57712C12.2176 3.54834 12.1528 3.53395 12.0874 3.52821C12.0292 3.52311 11.9708 3.52311 11.9126 3.52821C11.8472 3.53395 11.7824 3.54834 11.6529 3.57712Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { BankingIcon };
