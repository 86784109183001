import { IIconProps } from "@/components/icons/interface";
const PurchaseIcon = ({ width, height, color }: IIconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50014 17.5H17.3294C18.2793 17.5 18.7543 17.5 19.1414 17.3284C19.4827 17.1771 19.7748 16.9333 19.9847 16.6246C20.2228 16.2744 20.3078 15.8071 20.4777 14.8724L21.8285 7.44311C21.8874 7.11918 21.9169 6.95721 21.8714 6.83074C21.8315 6.71979 21.7536 6.62651 21.6516 6.56739C21.5353 6.5 21.3707 6.5 21.0414 6.5H5.00014M2 2.5H3.3164C3.55909 2.5 3.68044 2.5 3.77858 2.54433C3.86507 2.5834 3.93867 2.64628 3.99075 2.72563C4.04984 2.81565 4.06876 2.93551 4.10662 3.17523L6.89338 20.8248C6.93124 21.0645 6.95016 21.1843 7.00925 21.2744C7.06133 21.3537 7.13493 21.4166 7.22142 21.4557C7.31956 21.5 7.44091 21.5 7.6836 21.5H19"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { PurchaseIcon };
