const SettingsIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.39504 19.8711L9.97949 21.1856C10.1532 21.5768 10.4368 21.9093 10.7957 22.1426C11.1547 22.3759 11.5736 22.5001 12.0017 22.5C12.4298 22.5001 12.8488 22.3759 13.2077 22.1426C13.5667 21.9093 13.8502 21.5768 14.0239 21.1856L14.6084 19.8711C14.8164 19.4047 15.1664 19.0159 15.6084 18.76C16.0532 18.5034 16.5677 18.3941 17.0784 18.4478L18.5084 18.6C18.934 18.645 19.3636 18.5656 19.7451 18.3713C20.1265 18.1771 20.4434 17.8763 20.6573 17.5056C20.8714 17.135 20.9735 16.7103 20.951 16.2829C20.9285 15.8555 20.7825 15.4438 20.5306 15.0978L19.6839 13.9344C19.3825 13.5171 19.2214 13.0148 19.2239 12.5C19.2238 11.9866 19.3864 11.4864 19.6884 11.0711L20.535 9.90778C20.7869 9.56175 20.933 9.15007 20.9554 8.72267C20.9779 8.29528 20.8759 7.87054 20.6617 7.5C20.4478 7.12923 20.1309 6.82849 19.7495 6.63423C19.3681 6.43997 18.9385 6.36053 18.5128 6.40556L17.0828 6.55778C16.5722 6.61141 16.0576 6.50212 15.6128 6.24556C15.1699 5.98825 14.8199 5.59736 14.6128 5.12889L14.0239 3.81444C13.8502 3.42317 13.5667 3.09072 13.2077 2.8574C12.8488 2.62408 12.4298 2.49993 12.0017 2.5C11.5736 2.49993 11.1547 2.62408 10.7957 2.8574C10.4368 3.09072 10.1532 3.42317 9.97949 3.81444L9.39504 5.12889C9.18797 5.59736 8.83792 5.98825 8.39504 6.24556C7.95026 6.50212 7.43571 6.61141 6.92504 6.55778L5.4906 6.40556C5.06493 6.36053 4.63534 6.43997 4.25391 6.63423C3.87249 6.82849 3.55561 7.12923 3.34171 7.5C3.12753 7.87054 3.02549 8.29528 3.04798 8.72267C3.07046 9.15007 3.2165 9.56175 3.46838 9.90778L4.31504 11.0711C4.61698 11.4864 4.77958 11.9866 4.77949 12.5C4.77958 13.0134 4.61698 13.5137 4.31504 13.9289L3.46838 15.0922C3.2165 15.4382 3.07046 15.8499 3.04798 16.2773C3.02549 16.7047 3.12753 17.1295 3.34171 17.5C3.55582 17.8706 3.87274 18.1712 4.25411 18.3654C4.63548 18.5596 5.06496 18.6392 5.4906 18.5944L6.9206 18.4422C7.43127 18.3886 7.94581 18.4979 8.3906 18.7544C8.83513 19.011 9.18681 19.402 9.39504 19.8711Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9999 15.5C13.6568 15.5 14.9999 14.1569 14.9999 12.5C14.9999 10.8431 13.6568 9.5 11.9999 9.5C10.3431 9.5 8.99992 10.8431 8.99992 12.5C8.99992 14.1569 10.3431 15.5 11.9999 15.5Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { SettingsIcon };
