import { IIconProps } from "@/components/icons/interface";
const MaterialsIcon = ({ width, height, color }: IIconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 7.77777L12 12.5M12 12.5L3.49997 7.77777M12 12.5L12 22M21 16.5586V8.44147C21 8.09883 21 7.9275 20.9495 7.77471C20.9049 7.63953 20.8318 7.51545 20.7354 7.41076C20.6263 7.29242 20.4766 7.20922 20.177 7.04282L12.777 2.93171C12.4934 2.77415 12.3516 2.69537 12.2015 2.66448C12.0685 2.63715 11.9315 2.63715 11.7986 2.66448C11.6484 2.69537 11.5066 2.77415 11.223 2.93171L3.82297 7.04282C3.52345 7.20922 3.37369 7.29242 3.26463 7.41076C3.16816 7.51545 3.09515 7.63953 3.05048 7.77471C3 7.92751 3 8.09883 3 8.44147V16.5586C3 16.9012 3 17.0725 3.05048 17.2253C3.09515 17.3605 3.16816 17.4846 3.26463 17.5893C3.37369 17.7076 3.52345 17.7908 3.82297 17.9572L11.223 22.0683C11.5066 22.2259 11.6484 22.3047 11.7986 22.3356C11.9315 22.3629 12.0685 22.3629 12.2015 22.3356C12.3516 22.3047 12.4934 22.2259 12.777 22.0683L20.177 17.9572C20.4766 17.7908 20.6263 17.7076 20.7354 17.5893C20.8318 17.4846 20.9049 17.3605 20.9495 17.2253C21 17.0725 21 16.9012 21 16.5586Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5 10L7.5 5"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const MaterialsAdminIcon = ({ width, height, color }: IIconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 7.77777L12 12.5M12 12.5L3.49997 7.77777M12 12.5L12 22M14 21.3889L12.777 22.0683C12.4934 22.2259 12.3516 22.3047 12.2015 22.3356C12.0685 22.3629 11.9315 22.3629 11.7986 22.3356C11.6484 22.3047 11.5066 22.2259 11.223 22.0683L3.82297 17.9572C3.52346 17.7908 3.37368 17.7076 3.26463 17.5893C3.16816 17.4846 3.09515 17.3605 3.05048 17.2253C3 17.0725 3 16.9012 3 16.5586V8.44147C3 8.09883 3 7.92751 3.05048 7.77471C3.09515 7.63953 3.16816 7.51545 3.26463 7.41076C3.37368 7.29242 3.52345 7.20922 3.82297 7.04282L11.223 2.93171C11.5066 2.77415 11.6484 2.69537 11.7986 2.66448C11.9315 2.63715 12.0685 2.63715 12.2015 2.66448C12.3516 2.69537 12.4934 2.77415 12.777 2.93171L20.177 7.04282C20.4766 7.20922 20.6263 7.29242 20.7354 7.41076C20.8318 7.51545 20.9049 7.63953 20.9495 7.77471C21 7.9275 21 8.09883 21 8.44147L21 13M7.5 5.00002L16.5 10"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip0_3105_1112)">
        <path
          d="M16.2 19.75H18.36C19.0299 19.75 19.3648 19.75 19.6373 19.8361C20.251 20.03 20.7312 20.5302 20.9173 21.1694C21 21.4533 21 21.8022 21 22.5M15 15.75C15 16.9926 15.9671 18 17.16 18C18.3529 18 19.32 16.9926 19.32 15.75C19.32 14.5074 18.3529 13.5 17.16 13.5C15.9671 13.5 15 14.5074 15 15.75Z"
          stroke="white"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3105_1112">
          <rect
            width="11"
            height="11"
            fill="white"
            transform="matrix(-1 0 0 1 22 12.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { MaterialsIcon, MaterialsAdminIcon };
